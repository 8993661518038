<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('管理员管理')}}</span>
        <div>
          <el-button type="primary" @click='dialogForm' v-if="user_role['admin/add']" size="small">{{$t('新增管理员')}}</el-button>
          <!-- <el-button type="primary" size="small">导出EXCEL</el-button> -->
        </div>
      </div>
      <div style="margin:15px 0; width: 250px;">
        <el-input :placeholder="$t('请输入管理员名称')" v-model="name" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="getUserList(1)"></el-button>
        </el-input>
      </div>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column :label="$t('管理员ID')" prop="id" fixed="left" width="100" align="center" header-align="left">
        </el-table-column>
        <el-table-column :label="$t('管理员名称')" prop="username" width="200">
        </el-table-column>
        <el-table-column :label="$t('邮箱')" prop="email" width="200">
        </el-table-column>
        <el-table-column :label="$t('手机号')" prop="phone" width="160">
        </el-table-column>
        <el-table-column :label="$t('所属部门')" prop="bmname" width="150">
        </el-table-column>
        <el-table-column :label="$t('角色名称')" prop="rname" width="200">
        </el-table-column>
        <el-table-column :label="$t('状态')" prop="status" width="100">
        </el-table-column>
        <el-table-column :label="$t('创建时间')" prop="created_at" width="200">
        </el-table-column>
        <el-table-column fixed="right" :label="$t('操作')" align="center" header-align="left" width="250">
          <template slot-scope="scope">
           <!-- <el-button type="text" size="small" @click="getDetail(scope)">查看详情</el-button> -->
            <el-button type="text" @click='dialogForm(scope,true)' v-if="user_role['admin/edit']" size="small">{{$t('修改信息')}}</el-button>
            <el-button type="text" @click="delRole(scope)" v-if="user_role['admin/delete']" size="small">{{$t('删除管理员')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination>
    </el-card>
    <el-dialog :title="$t('新增管理员')" :visible.sync="addAdmin" width="80%">
      <el-row :gutter="20">
        <el-form :model="addform" :label-position="getLable" label-width="100px" ref="addform">
          <el-col :span="12">
            <el-form-item :label="$t('管理员名称')" required prop="username"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.username" clearable></el-input>
            </el-form-item>
          </el-col><!-- /管理员 -->
          <el-col :span="12">
            <el-form-item :label="$t('手机号')" required prop="phone"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.phone" clearable></el-input>
            </el-form-item>
          </el-col><!-- /手机号 -->
          <el-col :span="12">
            <el-form-item :label="$t('邮箱')" required prop="email"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.email" clearable></el-input>
            </el-form-item>
          </el-col><!-- /邮箱 -->
          <el-col :span="12">
            <el-form-item :label="$t('密码')" required prop="password1"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.password1" show-password></el-input>
            </el-form-item>
          </el-col><!-- /密码 -->
          <el-col :span="12">
            <el-form-item :label="$t('确认密码')" required prop="password2"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.password2" show-password></el-input>
            </el-form-item>
          </el-col><!-- /确认密码 -->
          <el-col :span="12">
            <el-form-item label="角色ID" required prop="r_id"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="addform.r_id" :placeholder="$t('请选择')">
                <el-option v-for="item in role" :label="item.name" :value="item.id" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /角色ID -->
          <el-col :span="12">
            <el-form-item :label="$t('所属部门')" required prop="bm_id"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="addform.bm_id" :placeholder="$t('请选择')">
                <el-option v-for="item in department" :label="item.name" :value="item.id" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /部门ID -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addAdmin = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="addMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('修改管理员信息')" :visible.sync="editAdmin" width="80%">
      <el-row :gutter="20">
        <el-form :model="editform" :label-position="getLable" label-width="100px" ref="editform">
          <el-col :span="12">
            <el-form-item :label="$t('管理员名称')" required prop="username"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="editform.username" clearable></el-input>
            </el-form-item>
          </el-col><!-- /管理员 -->
          <el-col :span="12">
            <el-form-item :label="$t('手机号')" required prop="phone"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="editform.phone" disabled></el-input>
            </el-form-item>
          </el-col><!-- /手机号 -->
          <el-col :span="12">
            <el-form-item :label="$t('邮箱')" required prop="email"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="editform.email" clearable></el-input>
            </el-form-item>
          </el-col><!-- /邮箱 -->
          <el-col :span="12">
            <el-form-item :label="$t('角色')" required prop="r_id"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="editform.r_id" :placeholder="$t('请选择')">
                <el-option v-for="item in role" :label="item.name" :value="item.id" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /角色ID -->
          <el-col :span="12">
            <el-form-item :label="$t('所属部门')" required prop="bm_id"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="editform.bm_id" :placeholder="$t('请选择')">
                <el-option v-for="item in department" :label="item.name" :value="item.id" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /部门ID -->
          <el-col :span="12">
            <el-form-item :label="$t('状态')" required prop="status"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="editform.status" :placeholder="$t('请选择')">
                <el-option :label="$t('正常')" :value="0"></el-option>
                <el-option :label="$t('锁定')" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /部门ID -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editAdmin = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="editMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <!-- <el-dialog title="管理员详情" :visible.sync="Detail" width="80%">
      <el-row :gutter="20">
        <el-form label-position="left" label-width="80px">
          <el-col :span="24">
            <el-form-item label="权限">

            </el-form-item>
          </el-col><
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Detail= false">关 闭</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import mixin from '../../utils/mixin.js'
export default {
  name: 'admin',
  mixins: [mixin],
  data () {
    return {
      addAdmin: false,
      editAdmin: false,
      UntreatedData: [],
      name: '',
      addform: {
        username: '',
        phone: '',
        email: '',
        password1: '',
        password2: '',
        r_id: '',
        bm_id: ''
      },
      editform: {
        id: '',
        username: '',
        phone: '',
        email: '',
        r_id: '',
        bm_id: '',
        status: ''
      },
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      },
      isModify: false,
      Detail: false,
      department: [],
      role: [],
      user_role: {}
    }
  },
  created () {
    this.getUserList()
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
  },
  methods: {
    // 获取所有角色与部门列表
    getRoleAll () {
      return new Promise((resolve, reject) => {
        this.$axios.post('admin/getRoleAndDepartmentAll').then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    // 打开表单
    dialogForm (item, isModify) {
      this.isModify = isModify
      if (isModify) {
        // console.log(item)
        this.getRoleAll().then((res) => {
          var Data = res.data.data
          this.editform = this.$common.forData2(item.row,this.editform)
          if (res.data.code === 0) {
            this.department = Data.department
            this.role = Data.role
            this.editAdmin = true
          }
        }).catch((error) => console.log('error', error))
      } else {
        this.getRoleAll().then((res) => {
          var Data = res.data.data
          if (res.data.code === 0) {
            this.department = Data.department
            this.role = Data.role
            this.addAdmin = true
          }
        }).catch((error) => console.log('error', error))
      }
    },
    // 获取管理员列表
    getUserList (page) {
      let params = {
        page: page?page:this.Page.page,
        name: this.name
      }
      this.$axios.post('admin/lists', params).then((res) => {
        const Data = res.data
        // console.log(Data)
        if (Data.code === 0) {
          this.UntreatedData = Data.data.data
          this.UntreatedData.forEach((value) => {
            value.status = value.status === 0 ? this.$t('正常') : this.$t('锁定')
          })
          this.Page = {
            pagesize: Data.data.pagesize,
            page: Data.data.page,
            maxpage: Data.data.maxpage,
            total: Data.data.total
          }
        }
      }).catch((error) => console.log(error))
    },
    // 添加管理员
    addMin () {
      let params = this.$common.forData(this.addform)
      this.$axios.post('admin/add', params).then((res) => {
        if (res.data.code === 0) {
          this.getUserList()
          this.addAdmin = false
          var that = this
          this.$common.resetField('addform', that)
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 修改管理员
    editMin () {
      // console.log(this.editform.r_id, this.editform.bm_id)
      let params = this.$common.forData(this.editform)
      this.$axios.post('admin/edit', params).then((res) => {
        // console.log(res)
        if (res.data.code === 0) {
          this.getUserList()
          this.editAdmin = false
          var that = this
          this.$common.resetField('editform', that)
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 删除管理员
    delRole (scope) {
      // console.log(scope)
      let that = this
      this.$MessageBox.confirm(that.$t('此操作将永久删除该管理员, 是否继续?'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('admin/delete', {
          id: scope.row.id
        }).then((res) => {
          // console.log(res)
          if (res.data.code === 0) {
            this.getUserList()
            this.$message({
              type: 'success',
              message: that.$t('删除成功!')
            })
          } else this.$message.warning(res.data.message)
        }).catch((error) => console.log(error))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: that.$t('已取消删除')
        })
      })
    },
    // 获取管理员详情
    getDetail (scope) {},
    // pageSize 改变时会触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    // currentPage 改变时会触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.getUserList(val)
    }
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  ::v-deep .el-select {
    width: 100% !important;
  }

  .el-input.is-disabled::v-deep .el-input__inner {
    color: #575757;
  }
</style>
